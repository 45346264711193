import axios from "axios";
import store from "../store/index";

// const api = axios.create({
//   baseURL: "https://sam.apitab.mx/api/",
// });

// // Interceptor para agregar el token a todas las solicitudes
// api.interceptors.request.use(
//   (config) => {
//     const token = localStorage.getItem("token");
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => Promise.reject(error)
// );

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error.response.data);
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.data.error === "Token no valido"
    ) {
      store.dispatch("salir"); // Asumiendo que tienes una mutación 'logout' para borrar el token
      //router.push('/login'); // Redirige al login
    }
    return Promise.reject(error);
  }
);

// Vehículos personales
export const getPassengerVehiculs = async (_id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(
    `vehiculoPersonal/list_passenger_vehicles/${_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const updatePassengerAccess = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.put("vehiculoPersonal/autorizar_acceso", datos);
  return data;
};

export const updateVehicleAccess = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.put(
    "vehiculoPersonal/autorizar_acceso_vehiculo",
    datos,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

// Tarifas
export const guardarCambiosTarifas = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    "solicitudUnica/guardar_cambios_tarifas/",
    datos,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const obtenerTarifa = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("solicitudUnica/obtener_tarifas/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const reprogramarSolicitud = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.put(
    "solicitudUnica/reprogramar_solicitud/",
    datos,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getFoliosSolicitudes = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("solicitudUnica/obtener_folios", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// Bitácora Embarcación
export const insertBitacoraEmbarcación = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post("bitacoraEmbarcacion/add", datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const listAccesoFolio = async (folio) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(`bitacoraEmbarcacion/list/${folio}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const listBitacora = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("bitacoraEmbarcacion/list_bitacora", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

// Usuario
export const forgotPassword = async (email) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    "usuario/request-reset",
    { email },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const resetPassword = async (tokenParam, password) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    "usuario/reset-password",
    { token: tokenParam, password },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

// Tickets
export const crearTicket = async (datos) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post("tickets/add", datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getTickets = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("tickets/", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const allTickets = async () => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get("tickets/all_tickets", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const getTicketsEstatus = async (estatus) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    "tickets/tickets",
    { estatus },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const addLogs = async (datos, _id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(`tickets/add_logs/${_id}`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const updateEstatus = async (estatus, _id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.post(
    `tickets/update_estatus/${_id}`,
    { estatus },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data;
};

export const getTicketsId = async (_id) => {
  const token = localStorage.getItem("token");
  const { data } = await axios.get(`tickets/get_ticket_id/${_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const generarPdfSU = async (datos) => {
  const token = localStorage.getItem("token");
  try {
    const response = await axios.post(
      `solicitudUnica/generar_reporte_su`,
      datos,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response; // Retorna el blob directamente
  } catch (error) {
    console.error("Error al generar el PDF:", error.response || error.message);
    throw error; // Lanza el error para manejarlo en el componente
  }
};

export const generarPdfCG = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`cambioGuardia/generar_reporte_cg`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response; // Retorna el blob directamente
};

export const generarPdfVP = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(
    `vehiculoPersonal/generar_reporte_vp`,
    datos,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response; // Retorna el blob directamente
};

export const guardarPsp = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`psp/add`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response; // Retorna el blob directamente
};

export const listarPsp = async (datos, page) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`psp/list`, {
    params: { page },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const guardarDocFinPsp = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`psp/add_doc_fin`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const listDocFinPsp = async (idPsp) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`psp/list_doc_fin/${idPsp}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const listDocTecPsp = async (idPsp) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`psp/list_doc_tec/${idPsp}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const guardarDocTecPsp = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`psp/add_doc_tec`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const listDocCartasPsp = async (idPsp) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`psp/list_doc_cartas/${idPsp}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const guardarDocCartasPsp = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`psp/add_doc_cartas`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const listDocGeneralPsp = async (idPsp) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`psp/list_doc_general/${idPsp}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const guardarDocGeneralPsp = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`psp/add_doc_general`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const listEvidenciaPsp = async (idPsp) => {
  const token = localStorage.getItem("token");
  const response = await axios.get(`psp/evidencia_psp/${idPsp}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const guardarEvidenciaPsp = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`psp/add_evidencia`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const deleteEvidenciaPsp = async (_id) => {
  const token = localStorage.getItem("token");
  const response = await axios.delete(`psp/delete_evidencia/${_id}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response;
};

export const generarPdfPsp = async (datos) => {
  const token = localStorage.getItem("token");
  const response = await axios.post(`psp/generar_reporte_psp`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response; // Retorna el blob directamente
};

export const actualizarAccesoPsp = async (datos, id) => {
  const token = localStorage.getItem("token");
  const response = await axios.put(`psp/update_access_psp/${id}`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response; // Retorna el blob directamente
};

export const actualizarAccesoFinanzas = async (datos, id) => {
  const token = localStorage.getItem("token");
  const response = await axios.put(`psp/update_access_finanzas/${id}`, datos, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response; // Retorna el blob directamente
};

export const aut1 = async (id) => {
  const token = localStorage.getItem("token");

  const response = await axios.post(`psp/aut1/${id}`, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response; // Retorna el blob directamente
};

export const cancellSolUnica = async (id, motivo) => {
  const token = localStorage.getItem("token");

  const response = await axios.put(
    `solicitudUnica/cancell`,
    { motivo_cancell: motivo, _id: id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const cancelCambioGuardia = async (id, motivo) => {
  const token = localStorage.getItem("token");

  const response = await axios.put(
    `cambioGuardia/cancell`,
    { motivo_cancell: motivo, _id: id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};

export const cancelVP = async (id, motivo) => {
  const token = localStorage.getItem("token");

  const response = await axios.put(
    `vehiculoPersonal/cancell`,
    { motivo_cancell: motivo, _id: id },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response;
};
