<template>
    <v-layout align-start>
        <v-overlay :value="loading">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-flex>
            <v-dialog v-model="dialogInfoFinaciera" max-width="800px">


                <v-card>
                    <v-toolbar color="primary" dark>Completa la información faltante</v-toolbar>
                    <v-container> <br>
                        <div class="mb-6">
                            <v-tooltip v-if="!showFile" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-card v-bind="attrs" v-on="on"
                                        class="d-flex align-center justify-space-between pa-3 mb-4" outlined>
                                        <div class="d-flex align-center">
                                            <v-icon color="primary" class="mr-2">post_add</v-icon>
                                            <span>Factura</span>
                                        </div>
                                        <div>
                                            <v-btn :href="psp_acceso?.cotizacion_file" target="_blank" color="primary"
                                                x-small fab class="mr-2">
                                                <v-icon>visibility</v-icon>
                                            </v-btn>
                                            <v-btn @click="deleteCotizacionFile()" v-if="usuario_log.rol == 'PSP'"
                                                target="_blank" color="primary" x-small fab>
                                                <v-icon>edit</v-icon>
                                            </v-btn>
                                        </div>
                                    </v-card>
                                </template>

                                <span v-if="usuario_log.rol == 'PSP'">Si se ingresó la factura al crear la solicitud, no
                                    modifique el archivo. Si fue
                                    una cotización, reemplace por la factura.</span>
                                <span v-if="usuario_log.rol == 'Finanzas'">El PSP modificará este registro si no se
                                    ingresó una factura válida..</span>

                            </v-tooltip>
                            <template v-else>
                                <v-file-input label="Factura" v-model="factura_edit" accept=".pdf" show-size
                                    dense></v-file-input>
                            </template>

                        </div>

                        <div class="mb-6">
                            <template v-if="usuario_log.rol == 'Finanzas'">
                                <v-card v-if="!showFileComPag"
                                    class="d-flex align-center justify-space-between pa-3 mb-4" outlined>
                                    <div v-if="!psp_acceso?.comprabante_pago" class="d-flex align-center">
                                        <v-icon color="primary" class="mr-2">post_add</v-icon>
                                        <span>Comprobante de pago no ingresado</span>
                                    </div>
                                    <div v-else class="d-flex align-center">
                                        <v-icon color="primary" class="mr-2">post_add</v-icon>
                                        <span>Comprobante de pago</span>
                                    </div>
                                    <div>
                                        <v-btn :href="psp_acceso?.comprabante_pago" target="_blank" color="primary"
                                            x-small fab class="mr-2">
                                            <v-icon>visibility</v-icon>
                                        </v-btn>
                                        <v-btn @click="deleteComprabantePago()" v-if="usuario_log.rol == 'PSP'"
                                            target="_blank" color="primary" x-small fab>
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>
                                <v-card v-else class="d-flex align-center justify-space-between pa-3 mb-4" outlined>
                                    <div class="d-flex align-center">
                                        <v-icon color="primary" class="mr-2">post_add</v-icon>
                                        <span>Comprobante de pago</span>
                                    </div>
                                    <div>
                                        <v-btn :href="psp_acceso?.comprabante_pago" target="_blank" color="primary"
                                            x-small fab class="mr-2">
                                            <v-icon>visibility</v-icon>
                                        </v-btn>
                                        <v-btn @click="deleteComprabantePago()" v-if="usuario_log.rol == 'PSP'"
                                            target="_blank" color="primary" x-small fab>
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>
                            </template>


                            <template v-else>
                                <v-file-input v-if="showFileComPag" v-model="comprobante_pago_edit"
                                    label="Comprobante de Pago" accept=".pdf" show-size dense></v-file-input>
                                <v-card v-else class="d-flex align-center justify-space-between pa-3 mb-4" outlined>
                                    <div class="d-flex align-center">
                                        <v-icon color="primary" class="mr-2">post_add</v-icon>
                                        <span>Comprobante de pago</span>
                                    </div>
                                    <div>
                                        <v-btn :href="psp_acceso?.comprabante_pago" target="_blank" color="primary"
                                            x-small fab class="mr-2">
                                            <v-icon>visibility</v-icon>
                                        </v-btn>
                                        <v-btn @click="deleteComprabantePago()" v-if="usuario_log.rol == 'PSP'"
                                            target="_blank" color="primary" x-small fab>
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>
                            </template>

                        </div>

                        <div class="">
                            <template v-if="usuario_log.rol == 'PSP'">
                                <v-card class="d-flex align-center justify-space-between pa-3 mb-4" outlined>
                                    <div class="d-flex align-center">
                                        <v-icon color="primary" class="mr-2">attach_money</v-icon>
                                        <span>Monto a pagar : ${{ currency(psp_acceso.monto_factura) }}</span>
                                    </div>

                                </v-card>
                            </template>


                            <template v-else>
                                <v-text-field label="Monto a pagar" type="number" v-model="monto_factura_edit"
                                    prepend-icon="attach_money"></v-text-field>
                            </template>

                        </div>


                        <div class="mb-6">
                            <template v-if="usuario_log.rol == 'PSP'">
                                <v-card class="d-flex align-center justify-space-between pa-3 mb-4" outlined>
                                    <div class="d-flex align-center">
                                        <v-icon color="primary" class="mr-2">post_add</v-icon>
                                        <span v-if="psp_acceso.factura_apitab">Factura APITAB</span>
                                        <span v-if="!psp_acceso.factura_apitab">Factura no ingresada</span>

                                    </div>
                                    <div>
                                        <v-btn v-if="psp_acceso.factura_apitab" :href="psp_acceso?.factura_apitab"
                                            target="_blank" color="primary" x-small fab class="mr-2">
                                            <v-icon>visibility</v-icon>
                                        </v-btn>

                                    </div>
                                </v-card>
                            </template>


                            <template v-else>
                                <v-file-input v-if="showFileFacApi" label="Factura APITAB"
                                    v-model="factura_apitab_edit" accept=".pdf" show-size dense></v-file-input>
                                <v-card v-else class="d-flex align-center justify-space-between pa-3 mb-4" outlined>
                                    <div class="d-flex align-center">
                                        <v-icon color="primary" class="mr-2">post_add</v-icon>
                                        <span>Factura APITAB</span>
                                    </div>
                                    <div>
                                        <v-btn :href="psp_acceso?.factura_apitab" target="_blank" color="primary"
                                            x-small fab class="mr-2">
                                            <v-icon>visibility</v-icon>
                                        </v-btn>
                                        <v-btn @click="deleteFacturaApitab()" v-if="usuario_log.rol == 'Finanzas'"
                                            target="_blank" color="primary" x-small fab>
                                            <v-icon>edit</v-icon>
                                        </v-btn>
                                    </div>
                                </v-card>
                            </template>

                        </div>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogInfoFinaciera = false">Cancelar</v-btn>
                        <v-btn v-if="usuario_log.rol == 'Finanzas'" text color="blue darken-1"
                            @click="updateInfoFinanazas" :loading="loading" :disabled="loading">Guardar</v-btn>
                        <v-btn v-if="usuario_log.rol == 'PSP'" text color="blue darken-1" @click="updateInfoPsp"
                            :loading="loading" :disabled="loading">Guardar</v-btn>
                    </v-card-actions>
                </v-card>

            </v-dialog>
            <v-dialog v-model="dialogDocumentos" max-width="1000px">
                <v-card>
                    <!-- Título -->
                    <v-card-title class="headline d-flex justify-space-between">
                        <span>Documentación </span>
                        <v-btn icon @click="dialogDocumentos = false">
                            <v-icon>close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <!-- Cuerpo -->
                    <v-card-text>
                        <v-row>
                            <!-- Lista de enlaces a la izquierda -->
                            <v-col cols="12" md="4">
                                <v-list>
                                    <!-- Verifica si hay documentos -->
                                    <v-subheader style="font-size: 15px; font-weight: bold;">Vehiculos</v-subheader>

                                    <v-list-item-group v-if="documentos_vehiculos_vis.length > 0">
                                        <!-- Itera sobre los documentos -->
                                        <v-list-item v-for="(file, index) in documentos_vehiculos_vis" :key="index"
                                            class="cursor-pointer hover:bg-light-blue" @click="viewPdf(file)">
                                            <!-- Icono inicial -->
                                            <v-list-item-icon>
                                                <v-icon color="blue">description</v-icon>
                                            </v-list-item-icon>

                                            <!-- Contenido del ítem -->
                                            <v-list-item-content>
                                                <v-list-item-title class="text-truncate">
                                                    Archivo - {{ index + 1 }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle> Documento PDF </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>

                                        <br>
                                        <v-divider inset></v-divider>
                                        <v-subheader style="font-size: 15px; font-weight: bold;">Personal</v-subheader>

                                        <v-list-item v-for="(file, index) in documentos_personal_vis" :key="index"
                                            class="cursor-pointer hover:bg-light-blue" @click="viewPdf(file)">
                                            <!-- Icono inicial -->
                                            <v-list-item-icon>
                                                <v-icon color="blue">description</v-icon>
                                            </v-list-item-icon>

                                            <!-- Contenido del ítem -->
                                            <v-list-item-content>
                                                <v-list-item-title class="text-truncate">
                                                    Archivo - {{ index + 1 }}
                                                </v-list-item-title>
                                                <v-list-item-subtitle> Documento PDF </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <br>
                                        <v-divider inset></v-divider>
                                        <v-subheader style="font-size: 15px; font-weight: bold;">Cotización o
                                            factura</v-subheader>

                                        <v-list-item class="cursor-pointer hover:bg-light-blue"
                                            @click="viewPdf(factura_url)">
                                            <!-- Icono inicial -->
                                            <v-list-item-icon>
                                                <v-icon color="blue">description</v-icon>
                                            </v-list-item-icon>

                                            <!-- Contenido del ítem -->
                                            <v-list-item-content>
                                                <v-list-item-title class="text-truncate">
                                                    Cotización
                                                </v-list-item-title>
                                                <v-list-item-subtitle> Documento PDF </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>



                                    <!-- Si no hay documentos -->
                                    <v-list-item v-else>
                                        <v-list-item-content>
                                            <v-list-item-title class="text-center text-grey">
                                                No hay archivos disponibles.
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-col>

                            <!-- Visor de PDF a la derecha -->
                            <v-col cols="12" md="8">
                                <div v-if="pdfLink">
                                    <iframe :src="pdfLink" width="100%" height="700px" style="border: none"></iframe>
                                </div>
                                <div v-else class="text-center">Selecciona un documento para verlo</div>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <!-- Acciones -->
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogAut1" max-width="500px">
                <v-card>
                    <v-toolbar color="primary" dark>Autorizar solicitud de acceso</v-toolbar>
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p style="font-size: 14px; color: gray">
                                    Se ha recibido la solicitud
                                    <strong>#AP-{{ itemSelected.folio_psp }}</strong> con referencia de pago <strong>{{
                                        itemSelected.referencia_pago }} </strong> para que ingrese personal y vehiculos
                                    en el
                                    muelle <strong>{{ itemSelected.muelle }}</strong>. Por favor, confirme si autoriza
                                    este acceso.
                                </p>

                                <v-row>

                                    <v-col cols="6">
                                        <span style="font-size: 12px">Personal:</span>

                                        <ul>
                                            <li style="font-size: 12px" v-for="(item, index) in itemSelected.pasajeros"
                                                :key="index">
                                                {{ item.nombre_completo }}

                                            </li>
                                        </ul>
                                    </v-col>
                                    <v-col cols="6">
                                        <span style="font-size: 12px">Vehiculos:</span>

                                        <ul>
                                            <li style="font-size: 12px" v-for="(item, index) in itemSelected.vehiculos"
                                                :key="index">
                                                {{ item.marca }} {{ item.tipo }}

                                            </li>
                                        </ul>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-container>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogAut1 = false">Salir</v-btn>
                        <v-btn color="blue darken-1" :loading="loading" text
                            @click="autorizarFinanzas()">Autorizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogAut2" max-width="500px">
                <v-card>
                    <v-toolbar color="primary" dark>Autorizar solicitud de acceso</v-toolbar>

                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p style="font-size: 14px; color: gray">
                                    Se ha recibido la solicitud
                                    <strong>#AP-{{ itemSelected.folio_psp }}</strong> con referencia de pago <strong>{{
                                        itemSelected.referencia_pago }} </strong> para que ingrese personal y vehiculos
                                    en el
                                    muelle <strong>{{ itemSelected.muelle }}</strong>. Por favor, confirme si autoriza
                                    este acceso.
                                </p>

                                <v-row>

                                    <v-col cols="6">
                                        <span style="font-size: 12px">Personal:</span>

                                        <ul>
                                            <li style="font-size: 12px" v-for="(item, index) in itemSelected.pasajeros"
                                                :key="index">
                                                {{ item.nombre_completo }}

                                            </li>
                                        </ul>
                                    </v-col>
                                    <v-col cols="6">
                                        <span style="font-size: 12px">Vehiculos:</span>

                                        <ul>
                                            <li style="font-size: 12px" v-for="(item, index) in itemSelected.vehiculos"
                                                :key="index">
                                                {{ item.marca }} {{ item.tipo }}

                                            </li>
                                        </ul>
                                    </v-col>
                                </v-row>


                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogAut2 = false">Salir</v-btn>
                        <v-btn color="blue darken-1" :loading="loading" text @click="autorizarOpib">Autorizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-dialog v-model="dialogAut3" max-width="500px">
                <v-card>
                    <v-toolbar color="primary" dark>Autorizar solicitud de acceso</v-toolbar>

                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <p style="font-size: 14px; color: gray">
                                    Se ha recibido la solicitud
                                    <strong>#AP-{{ itemSelected.folio_psp }}</strong> con referencia de pago <strong>{{
                                        itemSelected.referencia_pago }} </strong> para que ingrese personal y vehiculos
                                    en el
                                    muelle <strong>{{ itemSelected.muelle }}</strong>. Por favor, confirme si autoriza
                                    este acceso.
                                </p>

                                <v-row>

                                    <v-col cols="6">
                                        <span style="font-size: 12px">Personal:</span>

                                        <ul>
                                            <li style="font-size: 12px" v-for="(item, index) in itemSelected.pasajeros"
                                                :key="index">
                                                {{ item.nombre_completo }}

                                            </li>
                                        </ul>
                                    </v-col>
                                    <v-col cols="6">
                                        <span style="font-size: 12px">Vehiculos:</span>

                                        <ul>
                                            <li style="font-size: 12px" v-for="(item, index) in itemSelected.vehiculos"
                                                :key="index">
                                                {{ item.marca }} {{ item.tipo }}

                                            </li>
                                        </ul>
                                    </v-col>
                                </v-row>


                            </v-col>
                        </v-row>
                    </v-container>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="blue darken-1" text @click="dialogAut2 = false">Salir</v-btn>
                        <v-btn color="blue darken-1" :loading="loading" text
                            @click="autorizarOperaciones">Autorizar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <div class="pb-10 d-flex align-center justify-space-between">
                <div>
                    <h2>Solicitudes de acceso de PSP</h2>
                    <span style="font-size: 13px; color: gray">
                        Solicita y gestiona pases de acceso al muelle.
                    </span>
                </div>
                <v-btn v-if="usuario_log.rol == 'PSP' || usuario_log.rol == 'root'" @click="mostrarNuevo()">Nueva
                    Solicitud</v-btn>
            </div>

            <v-dialog v-model="dialogNuevaSolicitud" transition="dialog-bottom-transition" max-width="1200">
                <v-card>
                    <v-toolbar color="primary" dark>{{
                        editedIndex
                            ? "Editar solicitud de Acceso a personal"
                            : "Guardar solicitud de Acceso a personal"
                    }}</v-toolbar>
                    <v-card-text>
                        <div class="pa-8">
                            <v-stepper v-model="e1">
                                <loading-overlay :overlay="loading"></loading-overlay>

                                <v-stepper-header>
                                    <v-stepper-step :complete="e1 > 1" step="1">
                                        Datos de la solicitud
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :complete="e1 > 2" step="2">
                                        Lista del Personal
                                    </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :complete="e1 > 3" step="3"> Lista de vehiculos </v-stepper-step>

                                    <v-divider></v-divider>

                                    <v-stepper-step :complete="e1 > 4" step="4"> Detalles </v-stepper-step>
                                </v-stepper-header>

                                <v-stepper-items>
                                    <v-stepper-content step="1">
                                        <v-row no-gutters align="center">
                                            <v-flex xs12 sm12 lg12 x12>
                                                <v-radio-group v-model="tipo" row>
                                                    <v-radio label="Prestador de Servicios"
                                                        value="Prestador de Servicios"></v-radio>
                                                    <v-radio label="Cesionario" value="Cesionario"></v-radio>
                                                    <v-radio label="Contratista" value="Contratista"></v-radio>
                                                    <v-radio label="Armador" value="Armador"></v-radio>
                                                </v-radio-group>
                                            </v-flex>
                                            <v-row no-gutters>


                                                <v-col cols="6" class="text--secondary">
                                                    <v-dialog ref="dialog" v-model="modal" :return-value.sync="date"
                                                        persistent width="290px">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-text-field v-model="dateRangeText"
                                                                label="Periodo de Estadia" readonly dense outlined
                                                                v-bind="attrs" v-on="on"></v-text-field>
                                                        </template>
                                                        <v-date-picker v-model="dates" range scrollable>
                                                            <v-spacer></v-spacer>
                                                            <v-btn text color="primary"
                                                                @click="modal = false">Cancel</v-btn>
                                                            <v-btn text color="primary"
                                                                @click="$refs.dialog.save(date)">OK</v-btn>
                                                        </v-date-picker>
                                                    </v-dialog>

                                                </v-col>

                                                <v-col cols="6" class="text--secondary">
                                                    <v-autocomplete :items="muelles" v-on="on" label="Muelle" outlined
                                                        dense class=" ml-3" hide-details
                                                        v-model="muelle"></v-autocomplete>
                                                </v-col>

                                                <v-col cols="6" class="text--secondary">
                                                    <v-autocomplete v-model="embarcacion" class="pa-0" hide-details
                                                        label="Embarcación" outlined dense
                                                        :items="embarcaciones"></v-autocomplete>
                                                </v-col>

                                                <v-col cols="6" class="text--secondary">
                                                    <v-text-field v-model="compania" class="ml-3" outlined dense
                                                        label="Compañia"></v-text-field>

                                                </v-col>

                                                <v-col cols="12" class="text--secondary">
                                                    <v-checkbox v-model="ingreso_mat"
                                                        label="Ingreso de material o equipo"></v-checkbox>
                                                    <v-checkbox v-model="retiro_mat"
                                                        label="Retiro de material o equipo"></v-checkbox>
                                                </v-col>
                                            </v-row>
                                        </v-row>
                                        <v-btn color="primary" @click="checkDataStepper(1)"> Continuar </v-btn>
                                    </v-stepper-content>

                                    <v-stepper-content step="2">
                                        <div class="my-5">
                                            <v-row no-gutters>
                                                <v-col cols="12" class="text--secondary">
                                                    <v-flex xs12 sm2 md2 lg2 x12>
                                                        <v-btn class="ml-3" small dark color=""
                                                            @click="agregarDetalle()">
                                                            <v-icon dark>group_add </v-icon> <span class="ml-2">Agregar
                                                                personal</span>
                                                        </v-btn>
                                                    </v-flex>

                                                    <v-flex xs12 sm12 md12 lg12 xl12>
                                                        <v-data-table :headers="cabeceraDetalles" :items="pasajeros"
                                                            class="elevation-1">
                                                            <template slot="items" slot-scope="props">
                                                                <td class="text-xs-center">
                                                                    <v-text-field
                                                                        v-model="props.items.nombre_completo"></v-text-field>
                                                                </td>

                                                                <td class="text-xs-center">
                                                                    <v-text-field
                                                                        v-model="props.items.compania"></v-text-field>
                                                                </td>

                                                                <td class="text-xs-center">
                                                                    <v-text-field
                                                                        v-model="props.items.puesto"></v-text-field>
                                                                </td>
                                                                <td class="text-xs-center">
                                                                    <v-text-field
                                                                        v-model="props.items.licencia"></v-text-field>
                                                                </td>
                                                            </template>
                                                            <template v-slot:item.borrar="{ item }">
                                                                <v-icon small
                                                                    @click="eliminarPasajero(pasajeros, item)">
                                                                    delete
                                                                </v-icon>
                                                            </template>

                                                            <template v-slot:item.nombre_completo="{ item }">
                                                                <td class="text-xs-center">
                                                                    <v-text-field clearable="clearable"
                                                                        v-model="item.nombre_completo"></v-text-field>
                                                                </td>
                                                            </template>

                                                            <template v-slot:item.compania="{ item }">
                                                                <td class="text-xs-center">
                                                                    <v-text-field clearable="clearable"
                                                                        v-model="item.compania"></v-text-field>
                                                                </td>
                                                            </template>
                                                            <template v-slot:item.puesto="{ item }">
                                                                <td class="text-xs-center">
                                                                    <v-text-field clearable="clearable"
                                                                        v-model="item.puesto"></v-text-field>
                                                                </td>
                                                            </template>
                                                            <template v-slot:item.licencia="{ item }">
                                                                <td class="text-xs-center">
                                                                    <v-text-field clearable="clearable"
                                                                        v-model="item.licencia"></v-text-field>
                                                                </td>
                                                            </template> </v-data-table><br />
                                                        <div v-if="submited && !$v.pasajeros.required">
                                                            <v-alert v-model="alert" dismissible color="red"
                                                                border="left" elevation="2" colored-border icon="error">
                                                                <strong>Error</strong> campo pasajero es requerido es
                                                                requerido
                                                            </v-alert>
                                                        </div>
                                                    </v-flex>
                                                </v-col>
                                            </v-row>
                                            <div class="my-4">
                                                <v-btn small color="primary" @click="checkDataStepper(2)">
                                                    Continuar
                                                </v-btn>

                                                <v-btn class="mx-3" small @click="e1 = 1" text> Regresar </v-btn>
                                            </div>
                                        </div>
                                    </v-stepper-content>

                                    <v-stepper-content step="3">
                                        <v-row no-gutters>
                                            <v-col cols="12" class="text--secondary">
                                                <v-flex xs12 sm2 md2 lg2 x12>
                                                    <v-btn small dark color="" @click="agregarVehiculos()" class="ml-3">
                                                        <v-icon dark>directions_car </v-icon><span class="ml-3">Agregar
                                                            vehiculo</span>
                                                    </v-btn>
                                                </v-flex>
                                                <v-flex xs12 sm2 md2 lg2 x12> </v-flex>
                                            </v-col>

                                            <v-col cols="12" class="text--secondary">
                                                <v-data-table :headers="cabeceraVehiculos" :items="vehiculos"
                                                    class="elevation-1">
                                                    <template slot="items" slot-scope="props">
                                                        <td class="text-xs-center">
                                                            <v-text-field v-model="props.items.marca"></v-text-field>
                                                        </td>
                                                        <td class="text-xs-center">
                                                            <v-text-field v-model="props.items.placa"></v-text-field>
                                                        </td>
                                                        <td class="text-xs-center">
                                                            <v-text-field
                                                                v-model="props.items.vigenciaSeguro"></v-text-field>
                                                        </td>
                                                        <td class="text-xs-center">
                                                            <v-text-field v-model="props.items.color"></v-text-field>
                                                        </td>
                                                        <td class="text-xs-center">
                                                            <v-text-field v-model="props.items.tipo"></v-text-field>
                                                        </td>
                                                        <td class="text-xs-center">
                                                            <v-text-field
                                                                v-model="props.items.compania_aseguradora"></v-text-field>
                                                        </td>
                                                    </template>
                                                    <template v-slot:item.borrar="{ item }">
                                                        <v-icon small @click="eliminarVehiculo(vehiculos, item)">
                                                            delete
                                                        </v-icon>
                                                    </template>

                                                    <template v-slot:item.marca="{ item }">
                                                        <td class="text-xs-center">
                                                            <v-text-field clearable="clearable"
                                                                v-model="item.marca"></v-text-field>
                                                        </td>
                                                    </template>
                                                    <template v-slot:item.placa="{ item }">
                                                        <td class="text-xs-center">
                                                            <v-text-field clearable="clearable"
                                                                v-model="item.placa"></v-text-field>
                                                        </td>
                                                    </template>
                                                    <template v-slot:item.vigenciaSeguro="{ item }">
                                                        <td class="text-xs-center">
                                                            <input type="date" clearable="clearable"
                                                                v-model="item.vigenciaSeguro" />
                                                        </td>
                                                    </template>
                                                    <template v-slot:item.color="{ item }">
                                                        <td class="text-xs-center">
                                                            <v-text-field clearable="clearable"
                                                                v-model="item.color"></v-text-field>
                                                        </td>
                                                    </template>
                                                    <template v-slot:item.tipo="{ item }">
                                                        <td class="text-xs-center">
                                                            <v-text-field clearable="clearable"
                                                                v-model="item.tipo"></v-text-field>
                                                        </td>
                                                    </template>
                                                    <template v-slot:item.compania_aseguradora="{ item }">
                                                        <td class="text-xs-center">
                                                            <v-text-field clearable="clearable"
                                                                v-model="item.compania_aseguradora"></v-text-field>
                                                        </td>
                                                    </template>
                                                </v-data-table>
                                            </v-col>
                                        </v-row>

                                        <v-flex xs12 sm2 md12 lg12 xl12>
                                            <v-btn class="ma-2" @click="checkDataStepper(3)" small
                                                color="primary">Continuar</v-btn>
                                            <v-btn class="ma-2" @click="e1 = 2" small>Regresar</v-btn>
                                        </v-flex>
                                    </v-stepper-content>
                                    <v-stepper-content step="4">
                                        <v-flex xs12 sm2 md12 lg12 xl12>
                                            <template>
                                                <v-row dense>
                                                    <v-col cols="6" v-if="vehiculos.length > 0" sm="6" md="6">Adjunte
                                                        los archivos de los
                                                        vehiculos:

                                                        <v-file-input small-chips @change="validateFileSize"
                                                            :rules="fileRules" multiple v-model="file2" dense
                                                            accept=".pdf" outlined></v-file-input>
                                                        <v-row style="margin-top: -15px"
                                                            v-for="(item, index) in nombreArchivo2">
                                                            <v-col lg="3" sm="3" md="3">
                                                                <v-btn class="ma-2" dark x-small
                                                                    @click="descargarAdjuntos(item)">
                                                                    <v-icon dark x-small left> file_download
                                                                    </v-icon>Documento
                                                                    {{ index + 1 }}
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col lg="2" sm="2" md="2">
                                                                <v-btn class="ma-2" text x-small icon
                                                                    @click="eliminarAdjuntoVehiculo(nombreArchivo2, item)"
                                                                    color="red">
                                                                    <v-icon>backspace</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>

                                                    </v-col>

                                                    <v-col cols="6" sm="6" md="6">Adjunte los archivos del personal:

                                                        <v-file-input small-chips @change="validateFileSizePersonal"
                                                            multiple dense accept=".pdf" v-model="file3"
                                                            :rules="fileRulesPersonal" outlined></v-file-input>
                                                        <v-row style="margin-top: -15px"
                                                            v-for="(item, index) in nombreArchivo">
                                                            <v-col lg="3" sm="3" md="3">
                                                                <v-btn class="ma-2" dark x-small
                                                                    @click="descargarAdjuntos(item)">
                                                                    <v-icon dark x-small left> file_download
                                                                    </v-icon>Documento
                                                                    {{ index + 1 }}
                                                                </v-btn>
                                                            </v-col>
                                                            <v-col lg="3" sm="3" md="3">
                                                                <v-btn class="ma-2" x-small icon
                                                                    @click="eliminarAdjuntoPersonal(nombreArchivo, item)"
                                                                    color="red">
                                                                    <v-icon>backspace</v-icon>
                                                                </v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6">Adjunte la cotización o factura del
                                                        servicio:
                                                        <v-file-input dense accept=".pdf" v-model="cotizacion"
                                                            :rules="fileRulesCotizacion" outlined></v-file-input>
                                                        <v-row style="margin-top: -15px">
                                                            <v-col lg="3" sm="3" md="3">
                                                                <v-btn v-if="factura_url" class="ma-2" dark x-small
                                                                    @click="descargarAdjuntos(factura_url)">
                                                                    <v-icon dark x-small left> file_download
                                                                    </v-icon>Documento
                                                                </v-btn>
                                                            </v-col>

                                                        </v-row>

                                                    </v-col>
                                                    <v-col cols="6" sm="6" md="6">Monto total de factura o cotización:
                                                        <v-text-field dense outlined type="number" v-model="monto"
                                                            prepend-icon="attach_money"></v-text-field>


                                                    </v-col>

                                                </v-row>

                                                <v-flex xs12 sm12 lg12 x14>
                                                    <v-textarea v-model="detalles" outlined class="mt-3" dense
                                                        clearable="clearable" label="Motivo de la visita"></v-textarea>


                                                </v-flex>
                                            </template>
                                        </v-flex>
                                        <v-btn color="primary" @click="checkDataStepper(4)"> Guardar </v-btn>

                                        <v-btn @click="e1 = 3" text> Regresar </v-btn>
                                    </v-stepper-content>
                                    <v-stepper-content step="5">
                                        <v-btn class="ma-2" small outlined color="indigo">{{
                                            editedIndex ? "Editar" : "Guardar"
                                        }}</v-btn>

                                        <v-btn @click="e1 = 4" text> Regresar </v-btn>
                                    </v-stepper-content>
                                </v-stepper-items>
                            </v-stepper>
                        </div>
                        <div class="text-center ma-2">
                            <v-snackbar v-model="snackbar" absolute bottom right timeout="2000" color="red">
                                {{ text }}

                                <template v-slot:action="{ attrs }">
                                    <v-btn color="dark" text v-bind="attrs" @click="snackbar = false">
                                        Cerrar
                                    </v-btn>
                                </template>
                            </v-snackbar>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <v-btn text @click="dialogNuevaSolicitud = false">Salir</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <v-layout>
                <v-flex>
                    <material-card icon="local_shipping" title="Solicitudes creadas " class="px-5 py-3">
                        <v-layout>
                            <v-flex>
                                <v-row>
                                    <v-col cols="12">
                                        <div class="input-filter">
                                            <v-menu offset-y :nudge-width="150">
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn icon v-bind="attrs" v-on="on" color="primary" class="mb-2">
                                                        <v-icon>filter_list</v-icon>
                                                    </v-btn>
                                                </template>

                                                <v-list>
                                                    <v-list-item dense @click="showFilterFolio = true">
                                                        <v-list-item-title>
                                                            <span class="texto">Folio</span>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item dense @click="showFilterEmbarcacion = true">
                                                        <v-list-item-title>
                                                            <span class="texto">Embarcación</span>
                                                        </v-list-item-title>
                                                    </v-list-item>

                                                    <v-list-item dense @click="showFilterTipo = true">
                                                        <v-list-item-title>
                                                            <span class="texto">Tipo</span>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item dense @click="showFilterMuelle = true">
                                                        <v-list-item-title>
                                                            <span class="texto">Muelle</span>
                                                        </v-list-item-title>
                                                    </v-list-item>

                                                    <v-list-item dense @click="showFilterEstatus = true">
                                                        <v-list-item-title>
                                                            <span class="texto">Estatus</span>
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                    <v-list-item dense @click="showFilterFecha = true">
                                                        <v-list-item-title>
                                                            <span class="texto">Fecha</span></v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                            <div class="text-center"></div>
                                            <div v-if="arr.length == 0" class="filter-label">
                                                <span>Ningun filtro seleccionado...</span>
                                            </div>
                                            <div v-else class="filter-chips">
                                                <v-chip class="ml-2" v-for="(item, index) in arr" :key="index" close
                                                    close-icon="cancel" @click:close="eliminarFiltro(index)"
                                                    color="primary" small>
                                                    {{ item.label }}
                                                </v-chip>
                                            </div>
                                            <v-dialog v-model="showFilterFolio" transition="dialog-bottom-transition"
                                                max-width="350">
                                                <v-card>
                                                    <v-toolbar color="primary" dark>Busqueda por Folio</v-toolbar>
                                                    <v-card-text class="mt-10">
                                                        <v-text-field v-model="folio_filter" outlined dense prefix="VP-"
                                                            label="Ingrese el folio de la solicitud"></v-text-field>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <v-btn text @click="filtroLabelPush('counter')">Aplicar</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>

                                            <v-dialog v-model="showFilterEmbarcacion"
                                                transition="dialog-bottom-transition" max-width="350">
                                                <v-card>
                                                    <v-toolbar color="primary" dark>Busqueda por Embarcación</v-toolbar>
                                                    <v-card-text class="mt-10">
                                                        <v-autocomplete return-object v-model="embarcacion_filter"
                                                            outlined :items="embarcaciones" dense
                                                            label="Ingrese la embarcación"></v-autocomplete>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <v-btn text
                                                            @click="filtroLabelPush('embarcacion')">Aplicar</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>

                                            <v-dialog v-model="showFilterMuelle" transition="dialog-bottom-transition"
                                                max-width="350">
                                                <v-card>
                                                    <v-toolbar color="primary" dark>Busqueda por Muelle</v-toolbar>
                                                    <v-card-text class="mt-10">
                                                        <v-autocomplete label="Muelle" :items="muelles"
                                                            v-model="muelle_filter" hide-details dense
                                                            outlined></v-autocomplete>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <v-btn text @click="filtroLabelPush('muelle')">Aplicar</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>

                                            <v-dialog v-model="showFilterTipo" transition="dialog-bottom-transition"
                                                max-width="350">
                                                <v-card>
                                                    <v-toolbar color="primary" dark>Busqueda por Tipo</v-toolbar>
                                                    <v-card-text class="mt-10">
                                                        <v-autocomplete label="Tipo" :items="[
                                                            'Prestador de Servicios',
                                                            'Cesionario',
                                                            'Contratista',
                                                            'Armador',
                                                        ]" v-model="tipo_filter" hide-details dense
                                                            outlined></v-autocomplete>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <v-btn text @click="filtroLabelPush('tipo')">Aplicar</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>

                                            <v-dialog v-model="showFilterEstatus" transition="dialog-bottom-transition"
                                                max-width="350">
                                                <v-card>
                                                    <v-toolbar color="primary" dark>Busqueda por Estatus</v-toolbar>
                                                    <v-card-text class="mt-10">
                                                        <v-autocomplete v-if="usuario_log.rol == 'Agencia'"
                                                            label="Estatus" return-object :items="[
                                                                { text: 'En proceso', value: '0' },
                                                                { text: 'Aprobado', value: '2' },
                                                            ]" hide-details dense v-model="estatus_filter"
                                                            outlined></v-autocomplete>
                                                        <v-autocomplete v-else label="Estatus" return-object :items="[
                                                            { text: 'En proceso', value: '0' },
                                                            { text: 'Aut-1', value: '1' },
                                                            { text: 'Aprobado', value: '2' },
                                                        ]" hide-details dense v-model="estatus_filter"
                                                            outlined></v-autocomplete>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <v-btn text @click="filtroLabelPush('estado')">Aplicar</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>

                                            <v-dialog v-model="showFilterFecha" transition="dialog-bottom-transition"
                                                max-width="350">
                                                <v-card>
                                                    <v-toolbar color="primary" dark>Busqueda por Fecha</v-toolbar>
                                                    <v-card-text class="mt-10">
                                                        <v-text-field v-model="fecha_inicio_filter" type="date" outlined
                                                            dense label="Inicio"></v-text-field>
                                                        <v-text-field v-model="fecha_fin_filter" type="date" outlined
                                                            dense label="Fin"></v-text-field>
                                                    </v-card-text>
                                                    <v-card-actions class="justify-end">
                                                        <v-btn text
                                                            @click="filtroLabelPush('createdAt')">Aplicar</v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-flex>
                        </v-layout>
                        <v-simple-table class="rounded-table mt-3" style="" dense>

                            <thead class="rounded-table" style="background-color: rgb(124, 28, 68)">
                                <tr style="border-radius: 15px">
                                    <th class="white--text">Folio</th>
                                    <th class="white--text">Referencia Pago</th>
                                    <th class="white--text">PSP</th>
                                    <th class="white--text">Tipo</th>
                                    <th class="white--text">Estatus</th>
                                    <th class="white--text">Personal</th>
                                    <th class="white--text">Vehiculos</th>
                                    <th class="text-right white--text">Opciones</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr v-for="(item, index) in cambiosGuardia" :key="index">
                                    <td style="font-size: 12px; font-weight: bold">
                                        AP-{{ item.folio_psp }}
                                    </td>
                                    <td style="font-size: 13px; color: green; font-weight: bold">
                                        {{ item.referencia_pago }}
                                    </td>
                                    <td style="font-size: 12px; color: gray; font-weight: bold">
                                        {{ item.psp.razon_social }}
                                    </td>
                                    <td style="font-size: 12px; color: gray; font-weight: bold">
                                        {{ item.tipo }}
                                    </td>
                                    <td>
                                        <v-chip small class="ma-2" label outlined color="orange" v-if="
                                            item.estado == 0
                                        ">
                                            <v-avatar left>
                                                <v-icon small>restore</v-icon>
                                            </v-avatar>
                                            En proceso de autorización
                                        </v-chip>

                                        <v-chip small class="ma-2" label outlined color="blue" v-if="
                                            item.estado == 1
                                        ">
                                            <v-avatar left>
                                                <v-icon small>restore</v-icon>
                                            </v-avatar>
                                            Autorizado finanzas
                                        </v-chip>

                                        <v-chip small class="ma-2" color="blue" label outlined v-if="item.estado == 2">
                                            <v-avatar left>
                                                <v-icon small>done</v-icon>
                                            </v-avatar>
                                            Autorizado OPIP
                                        </v-chip>

                                        <v-chip small class="ma-2" color="green" label outlined v-if="item.estado == 3">
                                            <v-avatar left>
                                                <v-icon small>verified</v-icon>
                                            </v-avatar>
                                            Aprobado
                                        </v-chip>
                                        <v-chip small class="ma-2" color="green" text-color="white"
                                            v-if="item.estado == 5">
                                            <v-avatar left>
                                                <v-icon small>auto_delete</v-icon>
                                            </v-avatar>
                                            Servicio No Realizado
                                        </v-chip>
                                    </td>
                                    <td style="font-size: 12px; color: gray; font-weight: bold">
                                        {{ item.pasajeros.length }} acceso
                                    </td>
                                    <td style="font-size: 12px; color: gray; font-weight: bold">
                                        {{ item.vehiculos.length }} vehiculos
                                    </td>
                                    <td class="text-right">
                                        <v-menu bottom left rounded="lg">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn icon v-bind="attrs" v-on="on">
                                                    <v-icon>more_horiz</v-icon>
                                                </v-btn>
                                            </template>

                                            <v-list dense>
                                                <v-list-item
                                                    v-if="item.estado == '0' && (usuario_log.rol == 'root' || usuario_log.rol == 'PSP')"
                                                    @click="editItem(item)" link>
                                                    <v-list-item-title>Editar</v-list-item-title>
                                                </v-list-item>

                                                <v-list-item @click="generarPdfBackend(item)" link>
                                                    <v-list-item-title>Imprimir documento</v-list-item-title>
                                                </v-list-item>

                                                <v-list-item v-if="usuario_log.rol == 'OPIP' && item.estado == '1'"
                                                    @click="mostrarDialogAut2(item)" link>
                                                    <v-list-item-title>Aprobar solicitud</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item
                                                    v-if="usuario_log.rol == 'Operaciones' && item.estado == '2'"
                                                    @click="mostrarDialogAut3(item)" link>
                                                    <v-list-item-title>Aprobar solicitud</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="mostrarInformacionFinanciera(item)" link>
                                                    <v-list-item-title>Información Financiera</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-if="item.estado == '3'" @click="imprimirPaseAcceso(item)"
                                                    link>
                                                    <v-list-item-title>Imprimir pase de acceso</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click="mostrarDocumentacion(item)" link>
                                                    <v-list-item-title>Mostrar documentación</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item v-if="
                                                    usuario_log.rol == 'Finanzas' && item.estado == '0'
                                                " @click="mostrarDialogAut1(item)" link>
                                                    <v-list-item-title>Aprobar solicitud</v-list-item-title>
                                                </v-list-item>

                                            </v-list>
                                        </v-menu>
                                        <!-- <v-icon
                      small
                      v-if="item.estado == 0"
                      class="mr-2"
                      @click="editItem(item)"
                    >//
                      edit
                    </v-icon>
                    <v-icon v-if="item.estado == 2" class="mr-2" @click="editItem(item)">
                      edit
                    </v-icon>
  
                 -->
                                    </td>
                                </tr>
                            </tbody>
                        </v-simple-table>
                    </material-card>

                    <template>
                        <div class="text-center mt-3">
                            <v-pagination v-model="page" @input="listar()" :length="totalPages"
                                :total-visible="7"></v-pagination>
                        </div>
                    </template>

                </v-flex>
            </v-layout <v-container grid-list-sm class="pa-4 white" v-if="verNuevo">


        </v-flex>
    </v-layout>
</template>
<script>
import MaterialCard from "../components/MaterialCard.vue";
import axios from "axios";
import swal from "sweetalert";
import { required, email, minLength } from "vuelidate/lib/validators";
import { uploadDocumentPsp } from "../../helpers/handleUploadsDocument";
import LoadingOverlay from "../components/Loading.vue";
import { generateAccesVehiculos } from "./pdf/pase_acceso_vehiculos";

import { actualizarAccesoFinanzas, actualizarAccesoPsp, aut1, generarPdfPsp } from "../../api/endpoints";
import { generateAccesoPsp } from "./pdf/pase_acceso_psp";

export default {
    components: {
        MaterialCard,
        LoadingOverlay
    },
    icons: {
        iconfont: "fa4",
    },

    validations: {
        tipo: {
            required,
        },
        dates: {
            required,
        },
        muelle: {
            required,
        },

        embarcacion: {
            required,
        },
        compania: {
            required,
        },
        pasajeros: {
            required,
        },
        detalles: {
            required,
            minLength: minLength(10),
        },

        file3: {
            required,
        },
    },

    data() {
        return {
            //Documentación
            dialogDocumentos: false,
            documentos_vehiculos_vis: [],
            documentos_personal_vis: [],
            pdfLink: null,
            //----------
            nombreArchivo: [],
            showFilterFolio: false,
            showFilterEmbarcacion: false,
            showFilterMuelle: false,
            showFilterEstatus: false,
            showFilterTipo: false,
            showFilterFecha: false,
            folio_filter: "",
            embarcacion_filter: null,
            muelle_filter: "",
            estatus_filter: null,
            tipo_filter: "",
            fecha_inicio_filter: "",
            fecha_fin_filter: "",
            arr: [],
            e1: 1,
            dialogNuevaSolicitud: false,
            text: "",
            snackbar: false,
            dialog: false,
            page: 1,
            dialogAut1: false,
            dialogAut2: false,
            dialogAut3: false,
            totalPages: 0,
            itemSelected: [],
            usuario_log: [],
            search: "",
            cambiosGuardia: [],
            loading: false,
            headers: [
                { text: "Actions", value: "action", sortable: false },
                { text: "Folio", value: "counter", sortable: true },
                { text: "Estatus", value: "estado", sortable: true },
                { text: "Fecha", value: "fecha", sortable: true },
                { text: "Embarcacion", value: "embarcacion.nombre", sortable: false },
                { text: "Muelle", value: "muelle", sortable: false },
                { text: "Vehiculos", value: "vehiculos_adj", sortable: true },
                { text: "Personal", value: "personal_adj", sortable: false },
                { text: "Manifiesto", value: "manifiesto", sortable: false },
            ],
            editedIndex: false,
            _id: "",
            embarcacion: "",
            embarcaciones: [],
            muelles: [
        "FRONTERA",
        "CHILTEPEC",
        "ESPADAÑAL",
        "SANCHEZ MAGALLANES",
        "MUELLE FISCAL",
        "MUELLE PESCADORES",
        "CENTRO DE REPARACIONES NAVALES",
        "PARTICULAR",
      ],            muelle: "",
            nombre_completo: "",
            dates: [],
            folioOtraSolicitud: "",
            marca: "",
            agencias: [],
            tipo: "",
            ingreso_mat: false,
            rfc: "",
            retiro_mat: false,
            pasajerosList: [],
            panel: [0, 1, 2, 3, 4],
            extensionManifiesto: [],
            numbersRandomManifiesto: [],
            nombreManifiestos: [],
            compania: "",
            companias: [],
            compania_aseguradora: "",
            nombre: "",
            file3: "",
            agencia2: "",
            tipos: ["Ayudante", "Chofer"],
            tipoServicio: "",
            agenciasUsuario: [],
            cabeceraDetalles: [
                { text: "Borrar", value: "borrar", sortable: false, },
                { text: "Nombre Completo", value: "nombre_completo", sortable: false, },
                { text: "Compañia", value: "compania", sortable: false, },
                { text: "Puesto", value: "puesto", sortable: false, },
                { text: "No. de Licencia", value: "licencia", sortable: false, },
            ],
            cabeceraVehiculos: [
                { text: "Borrar", value: "borrar", sortable: false },
                { text: "Marca", value: "marca" },
                { text: "Modelo", value: "tipo" },
                { text: "Color", value: "color" },
                { text: "Placas", value: "placa" },
                { text: "Compañia Aseguradora", value: "compania_aseguradora" },

                { text: "Vigencia de Seguro", value: "vigenciaSeguro" },
            ],

            adjuntos: [
                { text: "Tarjeta de Circulacion", value: "tarjetaCirculacion" },
                { text: "Poliza", value: "poliza" },
            ],

            pasajeros: [],
            vehiculos: [],
            verNuevo: 0,
            usuario: "",
            fecha_solicitud: "",
            agencia: "",
            fecha: "",
            counter: "",
            vigencia: "",
            folio: "",
            fecha: "",
            estado: "",
            file3: [],
            file2: [],
            placa: "",
            prueba: [],
            file: "",
            fechaActual: "",
            fechaFinal: "",
            vigenciaSeguro: "",
            ingreso_mat: "",
            retiro_mat: "",
            color: "",
            personal: [],
            vehiculosAdjuntos: [],
            extensionVehiculos: [],
            numbersRandomVehiculos: [],
            nombreVehiculos: [],
            tipoVehiculo: "",
            mostrarAdjuntos: false,
            targetaCirculacion: "",
            poliza: "",
            numbersRandom: [],
            detalles: "",
            valida: 0,
            nombreArchivo2: "",
            extension: [],
            nombreArchivo3: "",
            nombreArchivos: [],
            fecha: null,
            validaMensaje: [],
            aprobacionAmls: "",
            aprobacionApi: "",
            adModal: 0,
            submited: "",
            modal: "",
            date: "",
            on: "",
            comprobanteModal: 0,
            adAccion: 0,
            cargo: "",
            adNombre: "",
            adId: "",
            maxSizeInBytes: 20 * 1024 * 1024, // 20 MB
            fileRules: [
                (file2) => this.validateRequired(file2) || "",
                (file2) =>
                    this.isTotalFileSizeValid(file2) || "El tamaño total debe ser menor de 20 MB",
            ],
            fileRulesPersonal: [
                (file3) => this.validateRequired(file3) || "",
                (file3) =>
                    this.isTotalFileSizeValid(file3) || "El tamaño total debe ser menor de 20 MB",
            ],
            fileRulesCotizacion: [
                (factura_url) => this.validateRequiredPsp(factura_url) || "",
                (factura_url) =>
                    this.isTotalFileSizeValidPsp(factura_url) || "El tamaño total debe ser menor de 20 MB",
            ],
            company_logo: {
                w: 460,
                h: 60,
            },
            //PSP
            cotizacion: null,
            monto: 0,
            factura_url: null,
            dialogInfoFinaciera: false,
            psp_acceso: [],
            showFile: false,
            showFileComPag: false,
            showFileFacApi : false,
            monto_factura_edit: 0,
            comprobante_pago_edit: null,
            factura_edit: null,
            factura_apitab_edit: null
        };
    },
    computed: {
        formTitle() {
            return !this.editedIndex ? "Nueva Solicitud" : "Editar Solicitud";
        },

        dateRangeText() {
            return this.dates.join(" al ");
        },
    },

    watch: {
        dialog(val) {
            val || this.close();
        },
    },

    created() {
        this.listar();
        this.selectEmbarcaciones();
        //this.selectCompanias();
        this.usuario_log = this.$store.state.usuario;
        //this.emailIn();
    },

    methods: {

        alerts(type) {
            switch (type) {
                case "error":
                    this.$notify({
                        title: "Error!",
                        text: "Formulario incompleto o error en el servidor",
                        type: "error",
                    });
                    break;
                case "success":
                    this.$notify({
                        title: "¡Éxito!",
                        text: "La operación se completo con éxito",
                        type: "success",
                    });
                    break;
            }
        },

        validateRequired(file2) {
            return file2 && file2.length > 0; // Verifica si hay al menos un archivo seleccionado
        },
        isTotalFileSizeValid(file2) {
            if (!file2 || file2.length === 0) return true; // No mostrar error si no hay archivos

            const totalSize = file2.reduce((acc, file2) => acc + file2.size, 0); // Sumar los tamaños de todos los archivos
            return totalSize <= this.maxSizeInBytes; // Comparar con el tamaño máximo permitido
        },


        // Verifica el tamaño total y limpia la selección si supera el límite
        validateFileSize(file2) {
            if (file2 && !this.isTotalFileSizeValid(file2)) {
                this.file2 = []; // Limpia la selección de archivos
                this.$emit("input", []); // Limpia los archivos seleccionados
                this.$notify({
                    title: "!Alerta!",
                    text: "Los archivos adjuntos deben pesar menos de 20mb",
                    type: "warning",
                });
            }
        },

        validateRequiredPersonal(file3) {
            return file3 && file3.length > 0; // Verifica si hay al menos un archivo seleccionado
        },
        isTotalFileSizeValidPersonal(file3) {
            if (!file3 || file3.length === 0) return true; // No mostrar error si no hay archivos

            const totalSize = file3.reduce((acc, file3) => acc + file3.size, 0); // Sumar los tamaños de todos los archivos
            return totalSize <= this.maxSizeInBytes; // Comparar con el tamaño máximo permitido
        },
        // Verifica el tamaño total y limpia la selección si supera el límite
        validateFileSizePersonal(file3) {
            if (file3 && !this.isTotalFileSizeValidPersonal(file3)) {
                this.file3 = []; // Limpia la selección de archivos
                this.$emit("input", []); // Limpia los archivos seleccionados
                this.$notify({
                    title: "!Alerta!",
                    text: "Los archivos adjuntos deben pesar menos de 20mb",
                    type: "warning",
                });
            }
        },

        validateRequiredPsp(file) {
    return !!file; // Verifica si hay un archivo seleccionado
},

        ///Validacion Rules Cotizacion
        isTotalFileSizeValidPsp(factura_url) {
            if (!factura_url) return true; // No mostrar error si no hay archivo

            const fileSize = factura_url.size; // Obtener el tamaño del archivo
            return fileSize <= this.maxSizeInBytes; // Comparar con el tamaño máximo permitido
        },
        // Verifica el tamaño total y limpia la selección si supera el límite
        validateFileSizePsp(file3) {
            if (file3 && !this.isTotalFileSizeValidPsp(file3)) {
                this.factura_url = []; // Limpia la selección de archivos
                this.$emit("input", []); // Limpia los archivos seleccionados
                this.$notify({
                    title: "!Alerta!",
                    text: "Los archivos adjuntos deben pesar menos de 20mb",
                    type: "warning",
                });
            }
        },


        imprimir() {
            var objeto = document.getElementById("solicitudGuardia"); //obtenemos el objeto a imprimir
            var ventana = window.open("", "_blank"); //abrimos una ventana vacía nueva
            ventana.document.write(objeto.innerHTML); //imprimimos el HTML del objeto en la nueva ventana
            ventana.document.close(); //cerramos el documento
            ventana.print(); //imprimimos la ventana
            ventana.close(); //cerramos la ventana
        },


        mostrarNuevo() {
            this.dialogNuevaSolicitud = true;
            this.editedIndex = false;
            this.e1 = 1; this.limpiar();
        },
        ocultarNuevo() {
            this.dialogNuevaSolicitud = false;
            this.editedIndex = false;
            this.e1 = 1;
            this.limpiar();
        },

        mostrarDatosAgencia() {
            this.listarAgencia(this.agencia);
        },

        eliminarPasajero(arr, item) {
            let i = arr.indexOf(item);
            if (i != -1) {
                arr.splice(i, 1);
            }
        },

        eliminarVehiculo(arr, item) {
            let i = arr.indexOf(item);
            if (i != -1) {
                arr.splice(i, 1);
            }
        },





        listarEmbarcacion(fecha, embar, muelle, detalles, usuario, correo) {
            let header = { Token: this.$store.state.token };
            let me = this;
            let embarcacion;

            let configuracion = { headers: header };
            axios
                .get("embarcacion/query?_id=" + embar, configuracion)
                .then(function (response) {
                    embarcacion = response.data;
                    var fechaLocal;
                    status = "en proceso";

                    me.enviar(fecha, embarcacion, muelle, detalles, usuario, status, correo);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },



        listarAgencia(id) {
            let me = this;
            const configuracion = {
                headers: {
                    Authorization: `Bearer ` + this.$store.state.token,
                },
            };
            axios
                .get("agencia/query?_id=" + id, configuracion)
                .then(function (response) {
                    me.agenciasUsuario = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        obtenerDatosPorFolio() {
            let me = this;
            let header = { Token: this.$store.state.token };
            let configuracion = { headers: header };
            axios
                .get(
                    "vehiculoPersonal/queryFolio?counter=" + this.folioOtraSolicitud,
                    configuracion
                )
                .then(function (response) {
                    if (response.data.agencia == me.$store.state.usuario.agencia) {
                        me.llenarFolio(response.data);
                    } else {
                        swal("Error", "Folio no autorizado", "error");
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        selectEmbarcaciones() {
            let me = this;
            let embarcacionArray = [];
            let header = { Token: this.$store.state.token };
            let configuracion = { headers: header };
            axios
                .get("embarcacion/listActivos", configuracion)
                .then((response) => {
                    embarcacionArray = response.data;
                    embarcacionArray.map(function (x) {
                        me.embarcaciones.push({ text: x.nombre, value: x._id });
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        selectAgencias() {
            let me = this;
            let agencaiArray = [];
            let header = { Token: this.$store.state.token };
            let configuracion = { headers: header };
            axios
                .get("agencia/list", configuracion)
                .then((response) => {
                    agencaiArray = response.data;
                    agencaiArray.map(function (x) {
                        me.agencias.push({ text: x.nombre, value: x._id });
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        validar() {
            this.valida = 0;
            this.validaMensaje = [];
            if (!this.embarcacion) {
                this.validaMensaje.push("La embarcación es obligatorio");
            }

            if (!this.muelle) {
                this.validaMensaje.push("El muelle es obligatoria");
            }

            if (this.validaMensaje.length) {
                this.valida = 1;
            }
            return this.valida;
        },

        agregarDetalle() {
            this.pasajeros.push({
                nombre_completo: "",
                compania: "",
                puesto: "",
                licencia: "",
            });
        },


        agregarVehiculos() {
            this.vehiculos.push({
                marca: "",
                placa: "",
                vigenciaSeguro: "",
                color: "",
                tipoVehiculo: "",
                targetaCirculacion: "",
                poliza: "",
                compania_aseguradora: "",
            });
        },

        limpiar() {
            this._id = "";
            this.muelle = "";
            this.fecha;
            this.factura_url = "";
            (this.usuario = ""),
                (this.embarcacion = ""),
                (this.vehiculos = ""),
                (this.fecha = ""),
                (this.nombreArchivos = []);
            (this.pasajeros = []),
                (this.vehiculos = []),
                (this.nombreArchivo = ""),
                (this.nombreArchivo2 = ""),
                (this.nombreArchivo3 = ""),
                (this.valida = 0),
                (this.validaMensaje = []),
                (this.editedIndex = false);
        },

        handleFileUpload(e) { },
        handleFileUploadPersonal(e) {
            this.file3 = e;
        },
        handleFileUploadManifiesto(e) {
            this.file = e;
        },
        handleFileUploadVehiculos(e) {
            this.file2 = e;
        },

        async guardar() {
            this.loading = true
            let me = this;
            const configuracion = {
                headers: {
                    Authorization: `Bearer ` + this.$store.state.token,
                },
            };
            let personal;
            let manifiestos;
            let vehiculosAdjuntos;
            let cotizacion;

            if (this.retiro_mat == "" && this.ingreso_mat == "") {
                this.retiro_mat = false;
                this.ingreso_mat = false;
            }
            if (this.retiro_mat == "") {
                this.retiro_mat = false;
            }
            if (this.ingreso_mat == "") {
                this.ingreso_mat = false;
            }

            let manifiestos_links = [];
            let vehiculos_links = [];
            let personal_links = [];
            let embarcacion = null;

            if (this.embarcacion) {
                embarcacion = this.embarcacion
            }


            if (this.editedIndex) {
                if (me.file) {
                    manifiestos = me.file;
                    const uploadPromises = manifiestos.map((adjunto) => uploadDocumentPsp(adjunto));
                    const urls = await Promise.all(uploadPromises);
                    urls.forEach((url) => manifiestos_links.push(url));
                    console.log("FILE1");

                    console.log(urls);

                }
                if (me.file2) {
                    vehiculosAdjuntos = me.file2;

                    const uploadPromises = vehiculosAdjuntos.map((adjunto) =>
                    uploadDocumentPsp(adjunto)
                    );
                    const urls = await Promise.all(uploadPromises);
                    urls.forEach((url) => vehiculos_links.push(url));
                    console.log("FILE2");
                    console.log(urls);
                }
                if (me.file3) {
                    personal = me.file3;

                    const uploadPromises = personal.map((adjunto) => uploadDocumentPsp(adjunto));
                    const urls = await Promise.all(uploadPromises);
                    urls.forEach((url) => personal_links.push(url));
                    console.log("FILE3");
                    console.log(urls);

                }
                if (me.cotizacion) {
                    cotizacion = await uploadDocumentPsp(me.cotizacion);
                }

                axios
                    .put(
                        "psp/update_solic_psp",
                        {
                            _id: this._id,
                            embarcacion: embarcacion,
                            muelle: this.muelle,
                            pasajeros: this.pasajeros,
                            fecha: this.dates,
                            vehiculos: this.vehiculos,
                            manifiesto: manifiestos_links,
                            vehiculos_adj: vehiculos_links,
                            personal_adj: personal_links,
                            tipo: this.tipo,
                            retiro_mat: this.retiro_mat,
                            monto_factura: this.monto,
                            cotizacion_file: cotizacion,
                            ingreso_mat: this.ingreso_mat,
                            compania: this.compania,
                            detalles: this.detalles,
                            inicioPrograma: new Date(this.dates[0]),
                            terminoPrograma: this.dates[1] ? new Date(this.dates[1]) : new Date(this.dates[0]),
                        },
                        configuracion
                    )
                    .then(function (response) {
                        swal(
                            "Nueva Solicitud de Acceso Actualizada",
                            "Solicitud de acceso a vehiculos y personal actualizada exitosamente",
                            "success"
                        );

                        me.limpiar();
                        me.ocultarNuevo();
                        me.listar();
                    })
                    .catch(function (error) {
                        me.close();
                        swal(
                            "Error",
                            "Error al ingresar la solicitud, verifica los datos ingresados" + error,
                            "error"
                        );
                    }).finally(() => {
                        this.loading = false
                    });
            } else {
                if (me.file) {
                    manifiestos = me.file;
                    const uploadPromises = manifiestos.map((adjunto) => uploadDocumentPsp(adjunto));
                    const urls = await Promise.all(uploadPromises);
                    urls.forEach((url) => manifiestos_links.push(url));
                }
                if (me.file2) {
                    vehiculosAdjuntos = me.file2;

                    const uploadPromises = vehiculosAdjuntos.map((adjunto) =>
                    uploadDocumentPsp(adjunto)
                    );
                    const urls = await Promise.all(uploadPromises);
                    urls.forEach((url) => vehiculos_links.push(url));
                }
                if (me.file3) {
                    personal = me.file3;

                    const uploadPromises = personal.map((adjunto) => uploadDocumentPsp(adjunto));
                    const urls = await Promise.all(uploadPromises);
                    urls.forEach((url) => personal_links.push(url));
                }

                if (me.cotizacion) {

                    cotizacion = await uploadDocumentPsp(me.cotizacion);

                }


                axios
                    .post(
                        "psp/add_solic_psp",
                        {
                            embarcacion: embarcacion,
                            usuario: this.$store.state.usuario._id,
                            muelle: this.muelle,
                            fecha: this.dates,
                            pasajeros: this.pasajeros,
                            vehiculos: this.vehiculos,
                            manifiesto: manifiestos_links,
                            vehiculos_adj: vehiculos_links,
                            personal_adj: personal_links,
                            detalles: this.detalles,
                            tipo: this.tipo,
                            retiro_mat: this.retiro_mat,
                            ingreso_mat: this.ingreso_mat,
                            compania: this.compania,
                            monto_factura: this.monto,
                            cotizacion_file: cotizacion,
                            inicioPrograma: new Date(this.dates[0]),
                            terminoPrograma: this.dates[1] ? new Date(this.dates[1]) : new Date(this.dates[0]), // Si dates[1] es null, usa dates[0]
                        },
                        configuracion
                    )
                    .then(function (response) {
                        swal(
                            "Nueva Solicitud de Acceso Ingresada" + " Folio VP" + response.data.counter,
                            "Solicitud de acceso a vehiculos y personal ingresada exitosamente",
                            "success"
                        );

                        me.limpiar();
                        me.ocultarNuevo();
                        me.close();
                        me.listar();
                    })
                    .catch(function (error) {
                        console.log(error)
                        me.$notify({
                            title: "¡Éxito!",
                            text: "La operación se completo con éxito",
                            type: "success",
                        });
                    }).finally(() => {
                        this.loading = false
                    });
            }
        },

        descargarManifiesto(manifiesto) {
            window.open(manifiesto, "_blank");
        },
        descargarVehiculos(vehiculos_adj) {
            window.open(vehiculos_adj, "_blank");
        },
        descargarPersonal(soporte) {
            window.open(soporte, "_blank");
        },
        listar() {
            const configuracion = {
                headers: {
                    Authorization: `Bearer ` + this.$store.state.token,
                },
            };
            this.loading = true
            let me = this;
            axios
                .post(
                    "psp/list_solic_psp?page=" + this.page, this.arr,
                    configuracion
                )
                .then(function (response) {
                    me.cambiosGuardia = response.data.reg;
                    me.totalPages = response.data.totalPaginas;

                })

                .catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    this.loading = false
                });
        },

        listarDetalles(id) {
            let me = this;
            let header = { Token: this.$store.state.token };
            let configuracion = { headers: header };
            axios
                .get("vehiculoPersonal/query?_id=" + id, configuracion)
                .then(function (response) {
                    me.pasajeros = response.data.pasajeros;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        listarDetallesVehiculos(id) {
            let me = this;
            let header = { Token: this.$store.state.token };
            let configuracion = { headers: header };
            axios
                .get("vehiculoPersonal/query?_id=" + id, configuracion)
                .then(function (response) {
                    me.vehiculos = response.data.vehiculos;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },



        verGuardia(item) {
            this.limpiar();
            this.fecha = item.fecha;
            this.folio = item.folio;
            this.muelle = item.muelle;
            this.detalles = item.detalles;
            this.agencia = item.agencia._id;
            this.embarcacion = item.embarcacion._id;
            this.listarDetalles(item._id);
            this.verNuevo = 1;
        },

        editItem(item) {
            this.limpiar();

            this.agencia = item.agencia;
            //  this.listarAgencia(item.agencia);

            this.muelle = item.muelle;
            this.embarcacion = item.embarcacion._id;
            //  this.listarDetalles(item._id);
            //  this.listarDetallesVehiculos(item._id);
            this.tipo = item.tipo;
            this.compania = item.compania;
            this.retiro_mat = item.retiro_mat;
            this.ingreso_mat = item.ingreso_mat;
            this.pasajeros = item.pasajeros
            this.vehiculos = item.vehiculos
            this.verNuevo = 1;
            this._id = item._id;
            this.folio = item.folio;
            this.monto = item.monto_factura;

            this.dates = item.fecha;
            this.nombreArchivo = item.personal_adj;
            this.nombreArchivo2 = item.vehiculos_adj;
            this.detalles = item.detalles;
            this.editedIndex = true;
            this.dialog = false;
            this.dialogNuevaSolicitud = true
            this.factura_url = item.cotizacion_file
        },

        llenarFolio(item) {
            this.limpiar();

            this.agencia = item.agencia;
            this.listarAgencia(item.agencia);

            this.muelle = item.muelle;
            this.embarcacion = item.embarcacion._id;
            this.listarDetalles(item._id);
            this.listarDetallesVehiculos(item._id);
            this.tipo = item.tipo;
            this.compania = item.compania;
            this.retiro_mat = item.retiro_mat;
            this.ingreso_mat = item.ingreso_mat;

            this._id = item._id;
            this.folio = item.folio;

            this.dates = item.fecha;
            this.nombreArchivo = item.manifiesto;
            this.nombreArchivo2 = item.vehiculos_adj;
            this.detalles = item.detalles;
        },

        download(item) {
            axios
                .get("vehiculoPersonal/getAccesoManifiesto/" + item.manifiesto, {
                    responseType: "arraybuffer",
                })
                .then(function (response) {
                    let blob = new Blob([response.data], { type: "content-type" });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "manifiesto.pdf";
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        downloadPersonal(item) {
            axios
                .get("vehiculoPersonal/getAccesoPersonal/" + item.personal_adj, {
                    responseType: "arraybuffer",
                })
                .then(function (response) {
                    let blob = new Blob([response.data], { type: "content-type" });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "personal.pdf";
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        downloadVehiculo(item) {
            axios
                .get("vehiculoPersonal/getAccesoVehiculo/" + item.vehiculos_adj, {
                    responseType: "arraybuffer",
                })
                .then(function (response) {
                    let blob = new Blob([response.data], { type: "content-type" });
                    let link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = "personal.pdf";
                    link.click();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        activarDesactivarMostrar(accion, item, folio) {
            console.log(this._id);
            this.adModal = 1;
            this.adNombre = item.folio;
            this.adId = folio.counter;
            this._id = item._id;
            if (accion == 1) {
                this.adAccion = 1;
            } else if (accion == 2) {
                this.adAccion = 2;
            } else if (accion == 3) {
                this.adAccion = 3;
            } else if (accion == 4) {
                this.adAccion = 4;
            } else {
                this.adMmodal = 0;
            }
        },
        activar() {
            let me = this;
            const configuracion = {
                headers: {
                    Authorization: `Bearer ` + this.$store.state.token,
                },
            };
            this.loading = true
            axios
                .put(
                    "vehiculoPersonal/activate",
                    { _id: this.itemSelected._id, aprobacionAmls: this.$store.state.usuario.nombre },
                    configuracion
                )
                .then(function (response) {

                    me.comprobanteModal = 0;
                    swal("Solicitud Aprobada", "Solicitud Aprobada con exito", "success");
                    me.dialogAut1 = false
                    me.itemSelected = []
                    me.listar();
                })
                .catch(function (error) {
                    console.log(error);
                }).finally(() => {
                    this.loading = false
                });
        },

        desactivar() {
            let me = this;
            let header = { Token: this.$store.state.token };
            let configuracion = { headers: header };
            axios
                .put(
                    "vehiculoPersonal/deactivate",
                    {
                        _id: this._id,
                        aprobacionAmls: this.$store.state.usuario.nombre,
                        motivo_cancelacion: this.motivo_cancelacion,
                    },
                    configuracion
                )
                .then(function (response) {
                    me.comprobanteModal = 0;
                    swal("Solicitud Cancelada", "Solicitud cancelada con exito", "success");
                    (me.adModal = 0),
                        (me.adAccion = 0),
                        (me.adNombre = ""),
                        (me.adId = ""),
                        me.listar();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        activarDesactivarCerrar() {
            this.adModal = 0;
        },

        mostrarDialogAut1(item) {
            this.itemSelected = item;
            this.dialogAut1 = true;
        },
        mostrarDialogAut2(item) {
            this.itemSelected = item;
            this.dialogAut2 = true;
        },
        mostrarDialogAut3(item) {
            this.itemSelected = item;
            this.dialogAut3 = true;
        },


        checkDataStepper(step) {
            const stepsFields = {
                1: [this.dateRangeText, this.muelle, this.tipo, this.compania],
                2: [this.pasajeros.length > 0],
                3: [this.vehiculos.length > 0],
                4: [this.file2, this.file3, this.detalles],
            };

            const requiredFields = stepsFields[step];
            const isAnyFieldMissing = requiredFields.some((field) => !field);

            if (isAnyFieldMissing) {
                this.text = "Todos los campos son obligatorios";
                this.snackbar = true;
            } else if (this.e1 == 4) {
                this.guardar()
            } else {
                this.e1 = step + 1; // Avanza al siguiente paso
            }
        },


        descargarAdjuntos(adjuntos) {
            window.open(adjuntos, "_blank");
        },

        autorizarOpib() {
            let me = this;
            this.loading = true;
            const configuracion = {
                headers: {
                    Authorization: `Bearer ` + this.$store.state.token,
                },
            };
            axios
                .put(
                    "psp/aut2",
                    {
                        _id: this.itemSelected._id,
                    },
                    configuracion
                )

                .then(function (response) {
                    swal(
                        "Solicitud Confirmada",
                        "Solicitud confirmada con exito, en espera de aprobación de APITAB",
                        "success"
                    );
                    me.dialogAut2 = false;
                    me.itemSelected = [];

                    me.listar();
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        autorizarOperaciones() {
            let me = this;
            this.loading = true;
            const configuracion = {
                headers: {
                    Authorization: `Bearer ` + this.$store.state.token,
                },
            };
            axios
                .put(
                    "psp/aut3",
                    {
                        _id: this.itemSelected._id,
                    },
                    configuracion
                )

                .then(function (response) {
                    swal(
                        "Solicitud Confirmada",
                        "Solicitud confirmada con exito, en espera de aprobación de APITAB",
                        "success"
                    );
                    me.dialogAut3 = false;
                    me.itemSelected = [];

                    me.listar();
                })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },


        eliminarAdjuntoPersonal(arr, item) {
            this.eliminarAdjuntoEndpointPersonal(item);

            let i = arr.indexOf(item);
            if (i != -1) {
                arr.splice(i, 1);
            }
        },

        eliminarAdjuntoVehiculo(arr, item) {
            this.eliminarAdjuntoEndpointVehiculo(item);

            let i = arr.indexOf(item);
            if (i != -1) {
                arr.splice(i, 1);
            }
        },

        eliminarAdjuntoEndpointVehiculo(file) {
            let me = this;
            this.loading = true;
            const configuracion = {
                headers: {
                    Authorization: `Bearer ` + this.$store.state.token,
                },
            };
            axios
                .put(
                    "vehiculoPersonal/eliminar_adj_vehiculo",
                    {
                        _id: this._id,
                        url: file,
                    },
                    configuracion
                )

                .then(function (response) { })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        eliminarAdjuntoEndpointPersonal(file) {
            let me = this;
            this.loading = true;
            const configuracion = {
                headers: {
                    Authorization: `Bearer ` + this.$store.state.token,
                },
            };
            axios
                .put(
                    "vehiculoPersonal/eliminar_adj_personal",
                    {
                        _id: this._id,
                        url: file,
                    },
                    configuracion
                )

                .then(function (response) { })
                .catch(function (error) {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },


        imprimirPaseAcceso(item) {
            const datos = {
                muelle: item.muelle,
                folio: item.folio_psp,
                _id: item._id,
                psp: item.psp.razon_social,
            };
            generateAccesoPsp(datos)
        },

        close() {
            this.dialog = false;
        },

        filtroLabelPush(tipo) {
            if (tipo == "counter") {
                let existe = this.arr.findIndex((e) => e.field == "counter");
                if (existe >= 0) {
                    this.arr.splice(existe, 1);
                }
                this.arr.push({
                    field: "counter",
                    value: this.folio_filter,
                    label: "Folio : " + this.folio_filter,
                    collection: "rf",
                });
            } else if (tipo == "embarcacion") {
                let existe = this.arr.findIndex((e) => e.field == "embarcacion");
                if (existe >= 0) {
                    this.arr.splice(existe, 1);
                }
                this.arr.push({
                    field: "embarcacion",
                    value: this.embarcacion_filter.value,
                    label: "Embarcación: " + this.embarcacion_filter.text,
                    collection: "embarcacion",
                });
            } else if (tipo == "tipo") {
                let existe = this.arr.findIndex((e) => e.field == "tipo");
                if (existe >= 0) {
                    this.arr.splice(existe, 1);
                }
                this.arr.push({
                    field: "tipo",
                    value: this.tipo_filter,
                    label: "Tipo : " + this.tipo_filter,
                    collection: "rf",
                });
            } else if (tipo == "muelle") {
                let existe = this.arr.findIndex((e) => e.field == "muelle");
                if (existe >= 0) {
                    this.arr.splice(existe, 1);
                }
                this.arr.push({
                    field: "muelle",
                    value: this.muelle_filter,
                    label: "Muelle : " + this.muelle_filter,
                    collection: "rf",
                });
            } else if (tipo == "estado") {
                let existe = this.arr.findIndex((e) => e.field == "estado");
                if (existe >= 0) {
                    this.arr.splice(existe, 1);
                }
                this.arr.push({
                    field: "estado",
                    value: this.estatus_filter.value,
                    label: "Estatus : " + this.estatus_filter.text,
                    collection: "rf",
                });
            } else if (tipo == "createdAt") {
                const fecha_inicio = new Date(this.fecha_inicio_filter);
                const fecha_fin = new Date(this.fecha_fin_filter);
                let existe = this.arr.findIndex((e) => e.field == "createdAt");
                if (existe >= 0) {
                    this.arr.splice(existe, 1);
                }
                this.arr.push({
                    field: "createdAt",
                    value: {
                        $gte: fecha_inicio,
                        $lte: fecha_fin,
                    },
                    label: "Entre : " + this.fecha_inicio_filter + " y " + this.fecha_fin_filter,
                    collection: "rf",
                });
            }

            this.showFilterFolio = false;
            this.showFilterEmbarcacion = false;
            this.showFilterProcedencia = false;
            this.showFilterMuelle = false;
            this.showFilterEstatus = false;
            this.showFilterFecha = false;
            this.showFilterTipo = false;
            this.listar();
            // this.showFilterFecha = false;
            // this.showFilterOperador = false;
            // this.showFilterTipoUnidad = false;
        },
        eliminarFiltro(index) {
            this.arr.splice(index, 1);
            this.listar();
        },

        mostrarDocumentacion(item) {
            this.dialogDocumentos = true;
            this.documentos_vehiculos_vis = item.vehiculos_adj;
            this.documentos_personal_vis = item.personal_adj;
            this.factura_url = item.cotizacion_file
            this.pdfLink = null;
        },
        viewPdf(url) {
            this.pdfLink = url;
        },

        async generarPdfBackend(item) {
            this.loading = true;

            await generarPdfPsp(item)
                .then((e) => {
                    const base64PDF = e.data;
                    const blob = this.base64ToBlob(base64PDF, "application/pdf");
                    const url = URL.createObjectURL(blob);
                    // Abre el PDF en una nueva ventana
                    window.open(url, `_blank`);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        async mostrarInformacionFinanciera(item) {
            this.dialogInfoFinaciera = true
            this.showFile = false
            this.showFileComPag = false
            this.showFileFacApi = false
            this.psp_acceso = item
            this.monto_factura_edit = item.monto_factura
        },

        deleteCotizacionFile() {
            this.showFile = true
        },

        deleteComprabantePago() {
            this.showFileComPag = true
        },

        deleteFacturaApitab() {
            this.showFileFacApi = true
        },


        async updateInfoPsp() {
            const data = {}
            this.loading = true
            if (this.factura_edit) {
                data.cotizacion_file = await uploadDocumentPsp(this.factura_edit);
            }
            if (this.comprobante_pago_edit) {
                data.comprabante_pago = await uploadDocumentPsp(this.comprobante_pago_edit)
            }
            actualizarAccesoPsp(data, this.psp_acceso._id).then(() => {
                this.listar()
                this.dialogInfoFinaciera = false
                this.alerts("success");

                this.factura_edit = null
                this.comprobante_pago_edit = null
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })

        },


        async updateInfoFinanazas() {
            const data = {}
            this.loading = true
            if (this.monto_factura_edit) {
                data.monto_factura = this.monto_factura_edit
            }
            if (this.factura_apitab_edit) {
                data.factura_apitab = await uploadDocumentPsp(this.factura_apitab_edit)
            }
            actualizarAccesoFinanzas(data, this.psp_acceso._id).then(() => {
                this.listar()
                this.dialogInfoFinaciera = false
                this.monto_factura_edit = null
                this.alerts("success");

                this.factura_apitab_edit = null
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })

        },

        async autorizarFinanzas() {
            const data = {}
            this.loading = true

            await aut1(this.itemSelected._id).then(() => {
                this.listar()
                this.dialogAut1 = false
                this.alerts("success");
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                this.loading = false
            })

        },

        base64ToBlob(base64, contentType) {
            const byteCharacters = atob(base64);
            const byteArrays = [];
            for (let offset = 0; offset < byteCharacters.length; offset += 512) {
                const slice = byteCharacters.slice(offset, offset + 512);
                const byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }
                const byteArray = new Uint8Array(byteNumbers);
                byteArrays.push(byteArray);
            }
            return new Blob(byteArrays, { type: contentType });
        },

        currency(value) {
            if (value == null) {
                value = 0;
            }
            let number = parseFloat(value);
            return number.toLocaleString("en-US");
        }

    },
};
</script>

<style>
#factura {
    padding: 20px;
    font-family: Arial, sans-serif;
    font-size: 16px;
}

#table_agencia {
    margin-right: 10px;
}

#imagen {
    width: 100px;
}

#imagen2 {
    width: 90px;
}

#fact {
    font-size: 14px;

    text-align: center;
}

#datos {
    float: left;
    margin-top: 0%;
    margin-left: 15%;
    margin-right: 2%;
    /*text-align: justify;*/
}

#encabezado {
    text-align: center;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 16px;
}

section {
    clear: left;
}

#cliente {
    text-align: left;
}

#facliente {
    width: 40%;
    border-collapse: collapse;
    border-spacing: 0;
    margin-bottom: 15px;
}

#fa {
    color: #ffffff;
    font-size: 14px;
}

#fa2 {
    color: #ffffff;
    font-size: 14px;
}

#facarticulo {
    width: 90%;
    border-collapse: collapse;
    border-spacing: 0;
    padding: 20px;
    margin-bottom: 15px;
    border: 0.5px solid #ddd;
}

#facarticulo thead {
    padding: 20px;
    background: #31467c;
    text-align: center;
    border-bottom: 1px solid #ffffff;
}

#solicitante {
    width: 60%;
    border-collapse: collapse;
    border-spacing: 1;
    padding: 20px;
    margin-bottom: 15px;
    border: 0.5px solid #ddd;
}

#solicitante thead {
    padding: 20px;
    background: #31467c;
    text-align: center;

    border-bottom: 1px solid #ffffff;
}

#gracias {
    text-align: center;
}

#tituloSolicitante {
    background-color: #808285;
    border: 1px solid #ddd;
    color: #ffffff;
}

#tituloPasajeros {
    background-color: #808285;
    border: 1px solid #ddd;
    color: #ffffff;
}

.input-filter {
    background-color: rgb(241, 241, 241);
    border-radius: 20px;
    padding: -5px;
    width: 100%;
    height: 40px;
}

.input-filter_seguimiento {
    background-color: rgb(226, 226, 226);
    border-radius: 20px;
    padding: -45px;
    width: 100%;
    height: 25px;
}

.input-filter_title {
    background-color: rgb(29, 29, 29);
    border-radius: 20px;
    padding: -45px;
    color: white;
    width: 100%;
    text-align: center;
    height: 25px;
}

.button-fab {
    bottom: 50px;
    height: 60px;
    position: fixed;
    right: 20px;
    width: 60px;
}

.filter-label {
    color: #b1adad;
    margin-top: -35px;
    margin-left: 40px;
    font-size: 13px;
    font-weight: normal;
}

.filter-chips {
    color: #b1adad;
    padding: 5px;
    margin-top: -41px;
    margin-left: 40px;
}
</style>